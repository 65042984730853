<template>
  <div>
    <HomePage/>
  </div>
</template>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
<script>
import HomePage from "@/views/HomePage";

export default {
  components: {HomePage}
}
</script>