export class TodoListItem {
    text = ''
    id = 0
    done = false
    constructor(text, id) {
        this.text = text
        this.id = id
        this.done = false
    }
}
